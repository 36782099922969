import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const BgImageStyles = styled.div`
  display: grid;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  .overlay {
    grid-area: 1/1;
    position: relative;
    place-items: center;
    display: grid;
    background-color: rgba(0, 57, 82, 0.7);
  }
`

const BackgroundImage = ({ imageData, children, underHeader = false, fullHeight = false }) => (
  <BgImageStyles>
    <GatsbyImage
      image={imageData}
      alt=""
      style={{
        gridArea: '1/1',
        paddingTop: underHeader ? '75px' : '0',
        height: fullHeight ? 'calc(100vh - 75px)' : 'auto',
        minHeight: '300px',
      }}
    />
    <div className="overlay">{children}</div>
  </BgImageStyles>
)

export default BackgroundImage
