import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { HeroStyles, HrStyles } from '../styles/GlobalStyles'
import SEO from '../components/SEO'
import BackgroundImage from '../components/BackgroundImage'
import Phone from '../assets/images/phone.svg'
import Email from '../assets/images/email.svg'
import Instagram from '../assets/images/instagram.svg'
import bemWordmark from '../assets/logo/bem-wordmark.png'

const ContactStyles = styled.div`
  padding: var(--spacing);

  p {
    font-size: 1.25rem;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  svg {
    height: 1.75rem;
    width: 1.25rem;
    margin-right: 0.75rem;
  }

  .instagram-cta {
    margin-top: var(--spacing);

    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin: 0;
    }
  }
`

export default function ContactPage({ data }) {
  // Hero image
  const contactImage = data?.file?.childImageSharp?.gatsbyImageData

  return (
    <>
      <SEO title="Contact" />
      <BackgroundImage imageData={contactImage} underHeader fullHeight>
        <HeroStyles>
          <div>
            <img className="logo" src={bemWordmark} alt="Bird's Eye Maine" />
            <h1>Contact Us</h1>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <ContactStyles>
        <p>Feel free to reach out with any questions or concerns you have!</p>
        <p>
          <a href="tel:+1-207-632-9369" rel="noreferrer nofollow">
            <Phone fill="#003a52" />
            207-632-9369
          </a>
        </p>
        <p>
          <a href="mailto:info@birdseyemaine.com" rel="noreferrer nofollow">
            <Email fill="#003a52" />
            info@birdseyemaine.com
          </a>
        </p>
        <HrStyles />
        <p className="instagram-cta">
          <a href="https://www.instagram.com/birdseye_maine" rel="nofollow noopener noreferrer" target="_blank">
            <Instagram fill="#003a52" />
            Follow us on instagram!
          </a>
        </p>
      </ContactStyles>
    </>
  )
}

export const query = graphql`
  {
    file(sourceInstanceName: { eq: "about" }, name: { eq: "OS" }) {
      name
      childImageSharp {
        id
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, formats: [AUTO, WEBP])
      }
    }
  }
`
